import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class ScoreServiceSoft extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._maritalStatusId = ''
    this._occupationId = ''
    this._incomeSourceId = ''
    this._income = 0
    this._expenses = 0
    this._dependents = 0
    this._homeSinceTotalMonths = 0
    this._jobSinceTotalMonths = 0
    this._workPhoneNumber = ''
    this._alternatePhoneNumber = ''

    this._hasCar = false
    this._carExpenses = 0
    this._previousHomeSinceTotalMonths = 0
    this._propertyOwnership = 0
    this._propertyOwnershipExpenses = 0
    this._previousJobSinceTotalMonths = 0

    this._creditDestination = ''
    this._businessName = ''
    this._businessPhone = 0
    this._businessLine = ''
    this._otherExpensePayment = 0
    this._landlineHomePhone = 0
    this._ssn = 0
    this._itin = 0
    // this._creditPayday = null
    this._payDayLoan = 0
    this._usArmyForces = false

    this._gender=''
    this._race=''
    this._ethnicity=''
    this._headOfHousehold=''

  }

  get data() {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      maritalStatusId: `${this._maritalStatusId}`,
      occupationId: `${this._occupationId}`,
      incomeSourceId: `${this._incomeSourceId}`,
      income: `${this._income}`,
      expenses: `${this._expenses}`,
      dependents: `${this._dependents}`,
      homeSinceTotalMonths: `${this._homeSinceTotalMonths}`,
      jobSinceTotalMonths: `${this._jobSinceTotalMonths}`,
      workPhoneNumber: `${this._workPhoneNumber}`,
      alternatePhoneNumber: `${this._alternatePhoneNumber}`,

      hasCar: this._hasCar,
      carExpenses: `${this._carExpenses}`,
      previousHomeSinceTotalMonths: `${this._previousHomeSinceTotalMonths}`,
      propertyOwnership: `${this._propertyOwnership}`,
      propertyOwnershipExpenses: `${this._propertyOwnershipExpenses}`,
      previousJobSinceTotalMonths: `${this._previousJobSinceTotalMonths}`,

      salary: 0,
      creditDestination: `${this._creditDestination}`,
      businessName: `${this._businessName}`,
      businessPhone: `${this._businessPhone}`,
      businessLine: `${this._businessLine}`,
      otherExpensePayment: this._otherExpensePayment,
      landlineHomePhone: `${this._landlineHomePhone}`,
      ssn: `${this._ssn}`,
      itin: `${this._itin}`,

      payDayLoan: this._payDayLoan,
      // creditPayday: this._creditPayday,
      usArmyForces: this._usArmyForces,

      gender: this._gender,
      race: this._race,
      ethnicity: this._ethnicity,
      headOfHousehold: this._headOfHousehold

    }
  }


  setBasicProfile(gender, race, ethnicity, headOfHousehold){
    this._gender=`${gender}`
    this._race=`${race}`
    this._ethnicity=`${ethnicity}`
    this._headOfHousehold=`${headOfHousehold}`
  }

  /**
   * @param  {Boleean} usArmyForces
   */
  setusArmyForces(usArmyForces) {
    this._usArmyForces = usArmyForces
  }

  /**
   * @param  {Number} payDayLoan
   */
  setpayDayLoan(payDayLoan) {
    this._payDayLoan = `${payDayLoan}`
  }

  // /**
  //   * @param  {Boleean} creditPayday
  //   */
  // setcreditPayday(creditPayday) {
  //   this._creditPayday = creditPayday
  // }

  /**
     * @param  {String} creditDestination
     */
  setCreditDestination(creditDestination) {
    this._creditDestination = `${creditDestination}`
  }

  /**
    * @param  {String} businessName
    */
  setBusinessName(businessName) {
    this._businessName = `${businessName}`
  }

  /**
  * @param  {Number} businessPhone
  */
  setBusinessPhone(businessPhone) {
    this._businessPhone = `${businessPhone}`
  }

  /**
   * @param  {String} businessLine
   */
  setBusinessLine(businessLine) {
    this._businessLine = `${businessLine}`
  }

  /**
  * @param  {Number} otherExpensePayment
  */
  setOtherExpensePayment(otherExpensePayment) {
    this._otherExpensePayment = `${otherExpensePayment}`
  }

  /**
 * @param  {Number} landlineHomePhone
 */
  setLandlineHomePhone(landlineHomePhone) {
    this._landlineHomePhone = `${landlineHomePhone}`
  }

  /**
* @param  {Number} ssn
*/
  setSsn(ssn) {
    this._ssn = `${ssn}`
  }

  /**
   * @param  {Number} itin
   */
  setItin(itin) {
    this._itin = `${itin}`
  }

  /**
   * @param  {String} maritalStatusId
   */
  setMaritalStatusId(maritalStatusId) {
    this._maritalStatusId = `${maritalStatusId}`
  }

  /**
   * @param  {String} occupationId
   */
  setOccupationId(occupationId) {
    this._occupationId = `${occupationId}`
  }


  /**
   * @param  {String} incomeSourceId
   */
  setIncomeSourceId(incomeSourceId) {
    this._incomeSourceId = `${incomeSourceId}`
  }

  /**
   * @param  {Number} income
   */
  setIncome(income) {
    this._income = income
  }

  /**
   * @param  {Number} expenses
   */
  setExpenses(expenses) {
    this._expenses = expenses
  }

  /**
   * @param  {Number} homeSinceTotalMonths
   */
  setHomeSinceTotalMonths(homeSinceTotalMonths) {
    this._homeSinceTotalMonths = homeSinceTotalMonths
  }

  /**
   * @param  {Number} dependents
   */
  setDependents(dependents) {
    this._dependents = dependents
  }

  /**
   * @param  {Number} jobSinceTotalMonths
   */
  setJobSinceTotalMonths(jobSinceTotalMonths) {
    this._jobSinceTotalMonths = `${jobSinceTotalMonths}`
  }

  /**
   * @param  {Number} workPhoneNumber
   */
  setWorkPhoneNumber(workPhoneNumber) {
    this._workPhoneNumber = `${workPhoneNumber}`
  }

  /**
 * @param  {Number} alternatePhoneNumber
 */
  setAlternatePhoneNumber(alternatePhoneNumber) {
    this._alternatePhoneNumber = `${alternatePhoneNumber}`
  }

  /**
 * @param  {Boolean} hasCar
 */
  setHasCar(hasCar) {
    this._hasCar = !!hasCar
  }

  /**
 * @param  {Number} carExpenses
 */
  setCarExpenses(carExpenses) {
    this._carExpenses = `${carExpenses}`
  }

  /**
* @param  {Number} previousHomeSinceTotalMonths
*/
  setPreviousHomeSinceTotalMonths(previousHomeSinceTotalMonths) {
    this._previousHomeSinceTotalMonths = `${previousHomeSinceTotalMonths}`
  }

  /**
 * @param  {String} propertyOwnership
 */
  setPropertyOwnership(propertyOwnership) {
    this._propertyOwnership = `${propertyOwnership}`
  }

  /**
* @param  {Number} propertyOwnershipExpenses
*/
  setPropertyOwnershipExpenses(propertyOwnershipExpenses) {
    this._propertyOwnershipExpenses = `${propertyOwnershipExpenses}`
  }

  /**
 * @param  {Number} previousJobSinceTotalMonths
 */
  setPreviousJobSinceTotalMonths(previousJobSinceTotalMonths) {
    this._previousJobSinceTotalMonths = `${previousJobSinceTotalMonths}`
  }

  returndata(){
    return this.data
  }
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/score',
      data: self.data
    }))
  }
  
  sendScoreSoft() {  
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/score/afi/soft',
      data: self.data
    }))
  }

  sendPassFico(data) {    
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/score',
      data: data
    }))
  }
}
