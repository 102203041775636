import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import ValidacionIdentidad from '../components/pages/afi/ValidacionIdentidadNokba'
import AttachMoney from '@material-ui/icons/AttachMoney';
import Home from '@material-ui/icons/Home';
import Check from '@material-ui/icons/Check';
import HowToRegIcon from '@material-ui/icons/HowToReg';


const iconos = { 1: <Home />, 2: <HowToRegIcon />, 3: <Check />, 4: <AttachMoney /> }

const ValidacionIdentidadPage = ({ pageContext, location }) => (

  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    companyName={pageContext.company}
    gradient
    currentStep={pageContext.step}
    iconos={iconos}
    location={location}
    pageContext={pageContext}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <ValidacionIdentidad pageContext={pageContext} />
      </>
    )}
  />
)

export default ValidacionIdentidadPage